import { AfterContentInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { I18nService, Logger, untilDestroyed } from '@app/core';
import { LoaderService, WindowresizeService } from '@app/shared';
import { environment as env } from '@env/environment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { merge, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AddOutputDialogService } from './cms/broadcast-studio/components/add-output-dialog/add-output-dialog.service';

const log = new Logger('App');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterContentInit {
  private subscriptions: Subscription[] = [];
  private langChangeSubscription: Subscription;
  showLoader: boolean;
  languages = [
    { code: 'en', label: 'English' },
    { code: 'es', label: 'Spanish' },
    { code: 'fr', label: 'French' },
    { code: 'da', label: 'Danish' },
    { code: 'de', label: 'German' },
    { code: 'jp', label: 'Japanese' },
    { code: 'ru', label: 'Russian' },
    { code: 'tw', label: 'Chinese (Traditional)' },
    { code: 'cn', label: 'Chinese (Simplified)' },
    { code: 'kr', label: 'Korean' },
  ];
  dropdownOpen = false;
  defaultLanguageIcon = 'icons8-usa-48.png';
  selectedLanguage: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private loaderService: LoaderService,
    private windowresizeService: WindowresizeService,
    private $AddOutputDialogService: AddOutputDialogService
  ) {}

  ngOnInit() {
    localStorage.setItem('goLiveStatus', 'false');
    localStorage.setItem('randomKey', Date.now().toString(36) + Math.random().toString(36).substr(2, 5));
    this.handleTwitchState();
    this.handleYoutubeState();
    this.handleTwitchState();
    this.handleFacebookState();
    this.handleTvstartupChannelState();
    this.handleInstagramState();
    this.handleRtmpState();
    this.handleTiktokState();

    this.windowresizeService.setSize({
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
    });
    this.subscriptions.push(
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
        let scrollToTop = window.setInterval(() => {
          let pos = window.pageYOffset;
          if (pos > 0) {
            window.scrollTo(0, pos - 50); // how far to scroll on each step
          } else {
            window.clearInterval(scrollToTop);
          }
        }, 16);
      })
    );
    // Setup logger
    if (env.production) {
      Logger.enableProductionMode();
    }

    log.debug('init');

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data),
        untilDestroyed(this)
      )
      .subscribe((event) => {
        const title = event.title;
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });

    // i18n
    // Initialize service
    const savedLanguage = localStorage.getItem('language');
    const defaultLanguage = savedLanguage ? savedLanguage : 'en';
    const supportedLanguages = ['en', 'es', 'jp', 'fr', 'da', 'de', 'ru', 'tw', 'cn', 'kr'];
    this.i18nService.init(defaultLanguage, supportedLanguages);

    if (savedLanguage) {
      this.selectedLanguage = savedLanguage;
    } else {
      this.selectedLanguage = 'en'; // reassign language just in case
      localStorage.setItem('language', this.selectedLanguage);
    }

    // Subscribe to language change
    this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.selectedLanguage = event.lang;
      localStorage.setItem('language', this.selectedLanguage);
      this.translateService.use(event.lang);
    });
  }

  onLanguageChange(languageCode: string) {
    this.selectedLanguage = languageCode;
    this.translateService.use(languageCode);
  }

  ngAfterContentInit(): void {
    this.loaderService.clear();
    this.loaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
    });
  }

  @HostListener('window:resize', ['$event'])
  resizeHandler($event: any): void {
    this.windowresizeService.setSize({
      innerWidth: $event.target.innerWidth,
      innerHeight: $event.target.innerHeight,
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.i18nService.destroy();
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  handleTwitchState() {
    if (localStorage.getItem('twitchStreamKeyValue')) {
      this.$AddOutputDialogService.accessTokenStatus.next(true);
    } else {
      this.$AddOutputDialogService.accessTokenStatus.next(false);
    }
  }

  handleYoutubeState() {
    if (localStorage.getItem('studioYtAccessToken') != null) {
      this.$AddOutputDialogService.youtubeAccessTokenStatus.next(true);
    } else {
      this.$AddOutputDialogService.youtubeAccessTokenStatus.next(false);
    }
  }

  handleFacebookState() {
    if (localStorage.getItem('studioFbAccessToken')) {
      this.$AddOutputDialogService.facebookTokenStatus.next(true);
    } else {
      this.$AddOutputDialogService.facebookTokenStatus.next(false);
    }
  }

  handleTvstartupChannelState() {
    if (localStorage.getItem('selectedChannelRtmpInfo') != null) {
      this.$AddOutputDialogService.tvstartupChannelStatus.next(true);
    } else {
      this.$AddOutputDialogService.tvstartupChannelStatus.next(false);
    }
  }

  handleInstagramState() {
    if (localStorage.getItem('instaInfo') != null) {
      this.$AddOutputDialogService.instagramToggleStatus.next(true);
    } else {
      this.$AddOutputDialogService.instagramToggleStatus.next(false);
    }
  }

  handleTwitterState() {
    if (localStorage.getItem('twitterInfo') != null) {
      this.$AddOutputDialogService.twitterToggleStatus.next(true);
    } else {
      this.$AddOutputDialogService.twitterToggleStatus.next(false);
    }
  }

  handleRtmpState() {
    if (localStorage.getItem('customRtmpInfo') != null) {
      this.$AddOutputDialogService.rtmpToggleStatus.next(true);
    } else {
      this.$AddOutputDialogService.rtmpToggleStatus.next(false);
    }
  }

  handleTiktokState() {
    if (localStorage.getItem('tiktokInfo') != null) {
      this.$AddOutputDialogService.tiktokToggleStatus.next(true);
    } else {
      this.$AddOutputDialogService.tiktokToggleStatus.next(false);
    }
  }
}
