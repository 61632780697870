import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BroadcastStudioMainService {
  link = new Subject();

  submitFormHandler = new BehaviorSubject(false);
  handleRandomKey = new BehaviorSubject(false);

  constructor() {}

  submitFormHandlerStatus(status: any) {
    this.submitFormHandler.next(status);
  }
}
