<div class="container mt-5">
  <div class="dialog-header">
    <div>
      <div class="modal-header" *ngIf="type == 'youtube'">
        <img class="youtube-icon" src="assets/images/youtube2.png" alt="Youtube" />
        <h2>YouTube</h2>
      </div>

      <div class="modal-header" *ngIf="type == 'vimeo'">
        <img class="youtube-icon" src="assets/images/vimeo-full.png" alt="Vimeo" style="width: 80px" />
      </div>

      <div class="modal-header" *ngIf="type == 'dropbox'">
        <img class="youtube-icon" src="assets/images/dropbox-full.png" alt="Dropbox" style="width: 100px" />
      </div>

      <div class="modal-header" *ngIf="type == 'aws-s3'">
        <img class="youtube-icon" src="assets/images/aws-s3.png" alt="AWS S3" style="width: 100px" />
      </div>
    </div>

    <div>
      <button
        mat-raised-button
        color="primary"
        class="mr-2"
        *ngIf="importSpecificVideo || importVideosFromChannel"
        (click)="backBtn()"
      >
        Back
      </button>
    </div>
  </div>

  <div class="option-wrapper" *ngIf="type != 'aws-s3'">
    <div class="option-section" *ngIf="!importSpecificVideo && !importVideosFromChannel">
      <div *ngIf="type != 'youtube'">
        <button mat-raised-button color="primary" type="submit" class="mr-2" (click)="selectImportStyle('channel')">
          Import Videos Using <span *ngIf="type == 'youtube' || type == 'vimeo'">Channel</span>
          <span *ngIf="type == 'dropbox'">Folder Path or Folder Url</span>
        </button>
      </div>

      <div style="padding: 10px" *ngIf="type != 'youtube'">or</div>

      <div>
        <button mat-stroked-button color="primary" type="submit" class="mr-2" (click)="selectImportStyle('specific')">
          {{
            type == 'dropbox'
              ? 'Import Specific Video Using File Path or File Url'
              : 'Import Specific Video Using Video Url'
          }}
        </button>
      </div>
    </div>

    <div class="container" *ngIf="type != 'aws-s3' && importVideosFromChannel">
      <form [formGroup]="channelForm" class="bg-light p-3 mb-3" style="margin-top: 10px" (ngSubmit)="fetchVideos()">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>
            <span *ngIf="type == 'youtube'">Channel ID or Channel Url:</span>
            <span *ngIf="type == 'vimeo'">Channel Url</span>
            <span *ngIf="type == 'dropbox'">Folder Path or Folder Url</span>
          </mat-label>
          <input matInput formControlName="channelId" />
          <mat-error *ngIf="channelForm.controls.channelId.invalid && channelForm.controls.channelId.touched">
            Please enter a valid Channel ID.
          </mat-error>
        </mat-form-field>
        <br />
        <div class="d-flex">
          <button
            mat-raised-button
            color="primary"
            type="submit"
            class="mr-2"
            [disabled]="channelForm.invalid || isFetching"
          >
            Fetch Videos
          </button>
          <button
            mat-raised-button
            color="success"
            type="button"
            class="ms-2"
            [disabled]="selectedVideos.length === 0"
            (click)="handleImportVideos()"
          >
            Submit Selected Videos
          </button>
        </div>
      </form>
      <br />
      <div
        class="modal"
        id="loadingModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="loadingModalLabel"
        aria-hidden="true"
        *ngIf="isFetching"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content bg-transparent border-0">
            <div class="modal-body text-center" style="width: 75%; margin: auto">
              <img
                src="https://i.pinimg.com/originals/47/af/82/47af824852bb6fb9f31e13d3e76a86be.gif"
                alt="Loading..."
                class="img-fluid"
              />

              <mat-progress-bar mode="indeterminate" style="margin: 10px 0px"></mat-progress-bar>
            </div>
          </div>
        </div>
      </div>

      <div id="videoResults" *ngIf="videos.length != 0 && !isFetching" style="width: 100%; padding-bottom: 2px">
        <div class="row">
          <div *ngFor="let video of videos; let i = index">
            <div
              class="card"
              (click)="toggleCardSelection(video)"
              [ngClass]="{ 'card-body-selected': video.isSelected }"
            >
              <img
                [src]="
                  type == 'dropbox'
                    ? video.thumbnail
                    : video.thumbnail_url
                    ? video.thumbnail_url
                    : '/assets/images/no-image.png'
                "
                class="card-img-top"
                [alt]="video.title"
                style="height: 100px"
              />
              <div class="card-body">
                <h5 class="card-title">{{ video.title ? video.title : video.name }}</h5>
                <div style="margin: 0px auto">
                  <a
                    class="watch-button"
                    [href]="video.video_url ? video.video_url : 'https://www.dropbox.com/home' + video.path_display"
                    target="_blank"
                    ><mat-icon aria-hidden="false" fontIcon="remove_red_eye"></mat-icon> <span>Watch Video</span></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div style="text-align: center">
          <mat-spinner color="primary" diameter="30" *ngIf="loadingMoreVideos" style="margin: auto"> </mat-spinner>
          <button
            mat-raised-button
            color="primary"
            type="button"
            class="mr-2"
            (click)="loadMoreVideos()"
            [disabled]="isLoadingDisabled()"
            *ngIf="!loadingMoreVideos"
          >
            Load More
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="importSpecificVideo">
      <form [formGroup]="videoForm" class="bg-light p-3 mb-3" style="margin-top: 10px">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label> {{ type == 'dropbox' ? 'Video File Path or Video File Url' : 'Video Url:' }} </mat-label>
          <input matInput formControlName="videoUrl" />
          <mat-error *ngIf="videoForm.controls.videoUrl.invalid && videoForm.controls.videoUrl.touched">
            Please enter {{ type == 'dropbox' ? 'Video File Path' : 'Video Url' }}
          </mat-error>
        </mat-form-field>
        <br />
        <div class="d-flex">
          <button
            mat-raised-button
            color="success"
            type="button"
            class="ms-2"
            [disabled]="videoForm.invalid"
            (click)="handleImportVideos()"
          >
            Import Video
          </button>
        </div>
      </form>
      <br />
    </div>
  </div>

  <div *ngIf="type === 'aws-s3' && !importVideosFromAwsS3 && !isLoggedInAws" class="dialog-container">
    <form [formGroup]="awsS3Form" class="form-container" id="awsS3Form">
      <mat-card-content>
        <div class="form-grid">
          <div class="form-column">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Tenant UUID</mat-label>
              <input formControlName="tenant_uuid" matInput id="tenant_uuid" name="tenant_uuid" required />
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Bucket</mat-label>
              <input formControlName="bucket" matInput id="bucket" name="bucket" required />
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Region</mat-label>
              <input formControlName="region" matInput id="region" name="region" required />
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Access Key</mat-label>
              <input formControlName="access_key" matInput id="access_key" name="access_key" required />
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Secret Key</mat-label>
              <input formControlName="secret_key" matInput id="secret_key" name="secret_key" required />
            </mat-form-field>
          </div>
          <div class="form-column">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Name</mat-label>
              <input formControlName="name" matInput id="name" name="name" required />
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Protocol</mat-label>
              <mat-select formControlName="protocol" id="protocol" name="protocol" required>
                <mat-option value="1">Amazon S3</mat-option>
                <mat-option value="17">S3 Compatible</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Host</mat-label>
              <input formControlName="host" matInput id="host" name="host" value="s3.amazonaws.com" required />
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Port</mat-label>
              <input formControlName="port" matInput type="number" id="port" name="port" value="443" required />
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Remote Path</mat-label>
              <input formControlName="remote_path" matInput id="remote_path" name="remote_path" required />
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>UUID (Optional)</mat-label>
              <input formControlName="ads_tag_url" matInput formControlName="ads_tag_url" />
            </mat-form-field>
            <mat-checkbox formControlName="is_ssl" color="primary" id="is_ssl" name="is_ssl" checked
              >Is SSL</mat-checkbox
            >
          </div>
        </div>
      </mat-card-content>
    </form>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="closeDialog()">Cancel</button>
      <button
        mat-raised-button
        color="primary"
        (click)="awsS3ImportVideos()"
        [disabled]="awsS3Form.get('tenant_uuid')?.invalid"
      >
        Get Videos
      </button>
    </mat-card-actions>
  </div>

  <div *ngIf="type === 'aws-s3' && importVideosFromAwsS3 && isLoggedInAws" class="dialog-container">
    <div *ngIf="isFetchingVideosFromAwsS3" class="loading-view">
      <mat-spinner diameter="80"></mat-spinner>
    </div>

    <div *ngIf="showVideoList" class="video-import">
      <button mat-raised-button color="primary" (click)="changeCredentialsAws()">Show Progress</button>
      <h2>Videos Found</h2>
      <div class="table-responsive-scroll">
        <table mat-table [dataSource]="videos" matSort (matSortChange)="handleSortChange($event)">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                color="primary"
                (change)="toggleAllRows($event)"
                [checked]="isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
              >
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let video">
              <mat-checkbox
                color="primary"
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(video) : null"
                [checked]="selection.isSelected(video)"
              >
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px">Title</th>
            <td mat-cell *matCellDef="let video">{{ video.title || '_' }}</td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 15px">Description</th>
            <td mat-cell *matCellDef="let video">{{ video.description || '_' }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="['select', 'title', 'description']"></tr>
          <tr mat-row *matRowDef="let video; columns: ['select', 'title', 'description']"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="3">
              <span>No videos available.</span>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <mat-card-actions *ngIf="!isFetchingVideosFromAwsS3">
      <button mat-raised-button color="primary" (click)="closeDialog()">Cancel</button>
      <button mat-raised-button color="primary" (click)="startAwsImport()">Import Videos</button>
      <button mat-raised-button color="primary" (click)="changeCredentialsAws()">Sign out of AWS S3</button>
    </mat-card-actions>
  </div>

  <div *ngIf="type === 'aws-s3' && isLoggedInAws && !importVideosFromAwsS3" class="table-responsive-scroll">
    <div fxLayout="row" fxLayoutAlign="end center" [style.margin-bottom.px]="15">
      <button mat-raised-button color="primary" (click)="importVideosAws()">Import more videos</button>
    </div>
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="handleSortChange($event)">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef style="width: 60px; text-align: center; padding-left: 24px">
          <mat-checkbox
            color="primary"
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          ></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" style="width: 60px; text-align: center; padding-left: 24px">
          <mat-checkbox
            color="primary"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          ></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="thumbnail" class="hide-on-small-screen">
        <th mat-header-cell *matHeaderCellDef>Thumbnail</th>
        <td mat-cell *matCellDef="let row">
          <img class="media-thumb" [src]="getThumbnail(row)" [alt]="row?.title" />
        </td>
      </ng-container>
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Video Title</th>
        <div *matCellDef="let row" class="mat-cell video-title-cell title-cell">
          <span class="is-advertisement" *ngIf="row.is_advertisement">Ad</span> {{ row.title || '_' }}
        </div>
      </ng-container>
      <ng-container matColumnDef="description" class="hide-on-small-screen">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Video Description</th>
        <div *matCellDef="let row" class="mat-cell video-title-cell">
          {{ row?.description || '_' }}
        </div>
      </ng-container>
      <ng-container matColumnDef="duration" class="hide-on-small-screen">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Duration</th>
        <td mat-cell *matCellDef="let row">{{ row?.duration | durationRounded }}</td>
      </ng-container>
      <ng-container matColumnDef="job_status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <div *matCellDef="let row" class="mat-cell video-title-cell">
          <div
            class="status-style"
            [ngClass]="{
              'status-completed': row?.job_status === 'COMPLETED',
              'status-failed': row?.job_status === 'FAILED',
              'status-progressing': row?.job_status === 'PROGRESSING',
              'status-encoding': row?.job_status === 'ENCODING'
            }"
          >
            <ng-container *ngIf="row?.job_status === 'ENCODING'; else statusLabel">
              Encoding <span class="progress-label">{{ row?.job_progress }}%</span>
            </ng-container>
            <ng-template #statusLabel>
              {{ row?.job_status }}
            </ng-template>
          </div>
        </div>
      </ng-container>
      <ng-container matColumnDef="created" class="hide-on-small-screen">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Added</th>
        <td mat-cell *matCellDef="let row">
          {{ row?.created ? (row.created | date: 'MM-dd-yyyy h:mm a') : '_' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let row" [style.padding]="'10px'" class="align-left">
          <button
            mat-flat-button
            class="actions-button flex-m-x"
            color="primary"
            class="align-left"
            (click)="handleEditVideo(row)"
          >
            Edit
          </button>
          <button mat-flat-button class="actions-button" [matMenuTriggerFor]="menu" color="primary">
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="handlePreviewVideo(row)" *ngIf="row?.output_file || row?.source_file">
              <mat-icon>visibility</mat-icon>Preview
            </button>
            <button mat-menu-item (click)="handleAddToCategory(row)"><mat-icon>add_circle</mat-icon>Add To</button>
            <button mat-menu-item (click)="handleEditThumbnail(row)"><mat-icon>edit</mat-icon>Edit Thumbnail</button>
            <button
              mat-menu-item
              (click)="handleReEncode(row)"
              *ngIf="
                row.job_status !== 'COMPLETED' ||
                row.output_file ||
                row.output_file?.endsWith('smil') ||
                row.source_file === null
              "
            >
              <mat-icon>refresh</mat-icon>{{ row.video_type == 'MERGED' ? 'Re Merge' : 'Re Encode' }}
            </button>
            <button mat-menu-item (click)="handleDownloadVideo(row)" *ngIf="row?.output_file || row?.source_file">
              <mat-icon>cloud_download</mat-icon>Download
            </button>
            <button mat-menu-item (click)="handleOpenAttachToAVideo(row?.uuid)" [disabled]="row?.is_advertisement">
              <mat-icon>video_call</mat-icon>
              <span>{{ row?.ad_video ? 'Replace Linked Ad Video' : 'Link an Ad Video' }}</span>
            </button>
            <button mat-menu-item [disabled]="row?.is_advertisement || !row?.ad_video" (click)="showAdVideo(row?.uuid)">
              <mat-icon>font_download</mat-icon> View Linked Ad
            </button>
            <button
              mat-menu-item
              (click)="setAsAdvertisementVideo(true, row?.uuid)"
              [disabled]="row?.is_advertisement || row?.ad_video"
            >
              <mat-icon>view_column</mat-icon>
              <span>Set as advertisement video</span>
            </button>
            <button
              mat-menu-item
              (click)="setAsAdvertisementVideo(false, row?.uuid)"
              [disabled]="!row?.is_advertisement || row?.ad_video"
            >
              <mat-icon>settings_backup_restore</mat-icon>
              <span>Unset as advertisement video</span>
            </button>
            <button mat-menu-item (click)="handleDeleteVideo(row)"><mat-icon>delete</mat-icon>Delete</button>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="6">
          <app-empty [description]="'Your library is empty. Please upload videos to your library.'"></app-empty>
        </td>
      </tr>
    </table>
  </div>
</div>
