import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { environment as env } from '@env/environment';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'lodash';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImporterApiService {
  constructor(private apiService: ApiService) {}

  fetchVideos(data_params: any, type: string) {
    let params = new HttpParams();
    map(data_params, (value: any, key: string) => {
      console.log(value);
      if (!!value) {
        params = params.set(key, value);
      }
    });

    let url = '';
    if (type == 'youtube') {
      url = `${env.qmsUploadUrl}/api/v1/ytb/list`;
    } else if (type == 'vimeo') {
      url = `${env.qmsUploadUrl}/api/v1/vimeo/list`;
    } else if (type == 'dropbox') {
      url = `${env.qmsUploadUrl}/api/v1/dbx/list`;
    }

    return this.apiService.get(url, (params = params));
  }

  importVideos(formData: any, type: any) {
    let url = '';
    if (type == 'youtube') {
      url = `${env.qmsUploadUrl}/api/v1/ytb/import`;
    } else if (type == 'vimeo') {
      url = `${env.qmsUploadUrl}/api/v1/vimeo/import`;
    } else if (type == 'dropbox') {
      url = `${env.qmsUploadUrl}/api/v1/dbx/import`;
    }
    return this.apiService.post(url, formData);
  }

  submitAwsCredentials(data: any): Observable<any> {
    // API to Submit Credentials to Back-end
    //
    // Data Sent is Credetials in JSON Form
    // Expected Return is Success or Fail
    return this.apiService.post(`/api/v3/importer/`, data);
  }

  fetchAwsVideos(identifier: string): Observable<any> {
    // API to get list of videos in S3 Bucket
    //
    // Expected Return is a JSON list of Videos (Title, Description)
    return this.apiService.get(`/api/v3/importer/?tenant_id=${identifier}`);
  }

  fetchAwsCredentials(tenant_id: string): Observable<any> {
    // API to get if there are credentials inputted before
    // This will allow the Import S3 FW to determine if it
    // should display the Sign in View or Status View of
    // video imports
    //
    // Data Sent is the tenant_id (Not sure what should be sent)
    // Expected Return is JSON list of credentials or Empty JSON Response
    return this.apiService.get(`/api/v3/importer-get-credentials/?tenant_id=${tenant_id}`);
  }

  submitAwsVideosSelected(data: any): Observable<any> {
    // API to send list of videos selected from S3 Bucket to be imported
    //
    // Data Sent is JSON List of Videos Selected (Title, Description)
    // Expected Return is Success or Fail
    return this.apiService.post(`api/v3/importer/aws`, data);
  }

  getAwsVideoUploadStatus(identifier: string): Observable<any> {
    // API to get List of Videos being uploaded and their statuses
    //
    // Expected Return, the same as the video-assets loader in the
    // Video Library Page
    //
    // {
    //    results {
    //        {
    //            title: "Something Title",
    //            slug: "Something slug",
    //            description: "Description",
    //            ..
    //            ..
    //        }
    //    }
    // }
    return this.apiService.get(`/api/`);
  }
}
