export interface LiveSetting {
  chatMessage?: Banner;
  banner?: { static: Banner; ticker: Banner };
  message?: Message;
  graphic?: GraphicSetting;
  displayChat?: boolean;
  brandColor?: BrandColor;
  bannerTickerStyle?: BannerTickerStyle;
  layoutStyle?: number;
  displayName?: boolean;
  themeStyle?: ThemeStyle;
  uuid?: string;

  logo?: {
    id: string;
    source: string;
    opacity?: number;
  };

  overlay?: {
    id: string;
    source: string;
  };

  videoClip?: {
    id: string;
    source: string;
  };

  backgroundImage?: {
    id: string;
    source: string;
  };

  backgroundVideo?: {
    uuid: string;
    source: any;
    optionWebgl?: GraphicWebgl;
  };

  isOpenCamera?: boolean;
  isOpenMic?: boolean;
  deviceSetting?: DeviceSetting;
}

export interface SettingStudio {
  greenScreenSettings?: GraphicWebgl;
  sourceItems?: SourceItem[];
  sourceItemsActive?: SourceItem[];
  bannerTickerStyle?: BannerTickerStyle;
  isOpenCamera?: boolean;
  isOpenMic?: boolean;
  logoOpacity?: number;
}

export interface LiveStudioRq {
  settings: SettingStudio;
}

export interface GraphicSetting {
  logo: string;
  logo_opacity: number;
  background: string;
  over_lay: string;
  video: string;
  display_name: boolean;
}

export interface Graphic {
  uuid: string;
  type: number;
  source?: string;
  is_active: boolean;
  thumbnail?: string;
  meta_data?: any;
}

export interface GraphicWebgl {
  similarity: number;
  smoothness: number;
  spill: number;
  color: string;
}

export interface Banner {
  uuid?: string;
  title: string;
  description: string;
  ticker_description: string;
  is_title_only: boolean;
  is_display: boolean;
}

export interface Message {
  uuid?: string;
  content: string;
  is_display: boolean;
}

export interface BannerTickerStyle {
  font?: string;
  fontSize?: string;
  backgroundColor?: string;
}

export interface SourceItem {
  id: string;
  type: LiveDemoSourceType;
  title: string;
  active: boolean;
  connection?: string;
  maximize: boolean;
  play?: boolean;
  volume?: number;
  has_volume?: boolean;
  is_main?: boolean;
  source?: any;
  background?: string;
  thumbnail?: string;
  avatar?: any;
  stream?: any;
  deviceSetting?: DeviceSetting;
  currentTime?: number;
  noAction?: boolean;
}

export interface LiveDemoItem {
  id: string;
  type: LiveDemoSourceType;
  maximize: boolean;
  is_main?: boolean;
  source?: any;
  volume?: number;
}

export interface BrandColor {
  primary?: string;
  secondary?: string;
}

export enum LiveDemoSourceType {
  MAIN_CAMERA,
  GUEST,
  SCREEN_SHARE,
  LOCAL_VIDEO,
  LOCAL_STUDIO,
  LOCAL_AUDIO,
  SUB_CAMERA,
}

export enum ThemeStyle {
  DEFAULT,
  SOLID,
  ROUNDED,
  BRACKET,
  OUTLINED,
  CLASSIC,
  THEME_01,
  THEME_02,
  THEME_03,
  THEME_04,
  THEME_05,
  THEME_06,
  THEME_07,
  THEME_08,
  THEME_09,
}

export interface DeviceSetting {
  videoDevice?: DeviceInfo;
  audioInputDevice?: DeviceInfo;
  audioOutputDevice?: DeviceInfo;
}

export interface DeviceInfo {
  id: string;
  label: string;
}
