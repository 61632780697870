import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ImporterApiService } from './video-importer.component.service';
import { MatDialog } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import {
  ChannelService,
  VideoService,
  Video,
  ShowAdVideoDialogComponent,
  ListCategoryDialogComponent,
  ListCategoryDialogModel,
  EditThumbnailDiaLogModel,
  EditThumbnailDialogComponent,
  PreviewVideoModel,
  PreviewVideoComponent,
  AssignTrailerClipModel,
  AssignTrailerClipComponent,
} from '@app/shared';
import { filter } from 'rxjs/operators';
import { AuthService } from '@app/auth/services';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { ConfirmDialogModel, ConfirmDialogComponent } from '@app/shared';
import { environment as env } from '@env/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Sort } from '@angular/material/sort';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Router } from '@angular/router';

@Component({
  selector: 'app-video-importer',
  templateUrl: './video-importer.component.html',
  styleUrls: ['./video-importer.component.scss'],
})
export class VideoImporterComponent implements OnInit {
  importSpecificVideo: boolean | false;
  importVideosFromChannel: boolean | false;
  importVideosFromAwsS3: boolean | false;
  public activeChannel$ = this.channelService.getActiveChannel();
  channelForm: FormGroup;
  videoForm: FormGroup;
  nextPageToken: string | null = null;
  selectedVideos: string[] = [];
  videosPerPage = 6;
  loadingMoreVideos = false;
  isFetching: boolean = false;
  videos: any[] = [];
  showLoadMoreButton = true;
  selectedVideosUrl: any[] = [];
  specificVideoUrl: string | null;
  activeChannelId: string | null = null;
  tenantId: string;
  type: string = null;
  raw_data: any = {};
  public params = {
    next_page_token: null as string | null,
    channel_url: null as string | null,
    channel_id: null as string | null,
    limit: null as number | null,
    oath_token: null as string | null,
    page: null as number | null,
    dbx_token: null as string | null,
    extensions: null as string | null,
    path: null as string | null,
    url: null as string | null,
    with_thumbnail: null as boolean | null,
  };
  file_extensions = 'mp4,mkv,mov,flv';
  awsS3Form: FormGroup;
  isFetchingVideosFromAwsS3: boolean | false;
  isLoggedInAws: boolean | false;
  showVideoList: boolean | false;
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  public displayedColumns: string[] = [
    'select',
    'thumbnail',
    'title',
    'description',
    'duration',
    'job_status',
    'created',
    'actions',
  ];
  public isLoading: boolean;
  selectedAdVideo: any;

  ngOnInit(): void {
    this.getLogInStatus();
  }

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private importerApiService: ImporterApiService,
    private channelService: ChannelService,
    private authService: AuthService,
    private notifier: NotifierService,
    public dialogRef: MatDialogRef<VideoImporterModel>,
    private videoService: VideoService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.type = data.type;
    this.initChannelForm();
    this.initVideoForm();
    this.initAwsS3Form();
    this.fetchActiveChannelAndUserTenantId();
  }

  private initChannelForm(): void {
    this.channelForm = this.formBuilder.group({
      channelId: ['', Validators.required],
    });
  }

  private initVideoForm(): void {
    this.videoForm = this.formBuilder.group({
      videoUrl: ['', Validators.required],
    });
  }

  private initAwsS3Form(): void {
    this.awsS3Form = this.formBuilder.group({
      tenant_uuid: ['', Validators.required],
      bucket: ['', Validators.required],
      region: ['', Validators.required],
      access_key: ['', Validators.required],
      secret_key: ['', Validators.required],
      name: ['', Validators.required],
      protocol: ['', Validators.required],
      host: ['', Validators.required],
      port: ['', Validators.required],
      remote_path: ['', Validators.required],
      ads_tag_url: ['', Validators.required],
      is_ssl: ['', Validators.required],
    });
  }

  private fetchActiveChannelAndUserTenantId(): void {
    forkJoin({
      activeChannel: this.activeChannel$.pipe(
        filter((channel) => !!channel),
        take(1)
      ),
      user: this.authService.getAccountInfo().pipe(
        filter((user) => !!user),
        take(1)
      ),
    }).subscribe(({ activeChannel, user }) => {
      this.activeChannelId = activeChannel.uuid;
      this.tenantId = user.tenant_id;
    });
  }

  selectImportStyle(choice: string) {
    if (choice == 'channel') {
      this.importSpecificVideo = false;
      this.importVideosFromChannel = true;
    } else {
      this.importSpecificVideo = true;
      this.importVideosFromChannel = false;
    }
  }

  fetchVideos() {
    if (this.channelForm.invalid) {
      return;
    }

    this.isFetching = true;
    const channelId = this.channelForm.value.channelId;

    let channelURL: string | null = null;
    const urlPattern = /^(http|https):\/\/[^ "]+$/;
    if (urlPattern.test(channelId)) {
      channelURL = channelId;
    }
    if (channelURL) {
      this.params.channel_url = channelURL;
    } else {
      this.params.channel_id = channelId;
    }

    this.params.limit = 10;

    if (this.type == 'vimeo') {
      this.params.oath_token = localStorage.getItem('vimeo_token');
      this.params.page = 1;
    } else if (this.type == 'dropbox') {
      if (this.params.channel_url) {
        this.params.channel_url = this.params.channel_url.replace('%20', ' ');
        this.params.url = this.params.channel_url;
        this.params.extensions = this.file_extensions;
        this.params.dbx_token = localStorage.getItem('dropbox_token');
        this.params.with_thumbnail = true;
        console.log(this.params);
        delete this.params.channel_url;
      } else {
        this.params.path = channelId == '/' ? '' : channelId;
        this.params.extensions = this.file_extensions;
        this.params.dbx_token = localStorage.getItem('dropbox_token');
        this.params.with_thumbnail = true;
        delete this.params.channel_id;
      }
    }

    delete this.params.next_page_token;
    this.importerApiService.fetchVideos(this.params, this.type).subscribe(
      (data: any) => {
        this.isFetching = false;
        if (data.code === 200) {
          this.raw_data = data.data;
          // this.videos = data.data.videos;
          if (this.type == 'dropbox') {
            this.videos = data.data.files;
          } else {
            this.videos = data.data.videos;
          }

          if (this.type == 'dropbox' || this.type == 'youtube') {
            this.nextPageToken = data.data.next_page_token;
          }
        } else {
          this.notifier.notify('error', data.message);
        }
      },
      (error) => {
        this.isFetching = false;
        this.notifier.notify('error', error);
      }
    );

    this.params.channel_id = null;
    this.params.channel_url = null;
  }

  loadMoreVideos() {
    if (this.nextPageToken || this.type == 'vimeo') {
      this.params.next_page_token = this.nextPageToken;
      this.loadingMoreVideos = true;

      const channelId = this.channelForm.value.channelId;
      let channelURL: string | null = null;
      const urlPattern = /^(http|https):\/\/[^ "]+$/;

      if (urlPattern.test(channelId)) {
        channelURL = channelId;
      }

      if (channelURL) {
        this.params.channel_url = channelURL;
      } else {
        this.params.channel_id = channelId;
      }

      this.params.limit = 10;

      if (this.type == 'vimeo') {
        this.params.oath_token = localStorage.getItem('vimeo_token');
        this.params.page = this.params.page + 1;
      } else if (this.type == 'dropbox') {
        if (this.params.url) {
          this.params.extensions = this.file_extensions;
          this.params.dbx_token = localStorage.getItem('dropbox_token');
          this.params.with_thumbnail = true;
          console.log(this.params);
          delete this.params.channel_url;
        } else {
          this.params.extensions = this.file_extensions;
          this.params.dbx_token = localStorage.getItem('dropbox_token');
          this.params.with_thumbnail = true;
          delete this.params.channel_id;
        }
      }

      this.importerApiService.fetchVideos(this.params, this.type).subscribe(
        (data: any) => {
          this.loadingMoreVideos = false;
          if (data.code === 200) {
            this.raw_data.page = data.data.page;
            this.raw_data.total = data.data.total;
            let videos = this.type == 'dropbox' ? data.data.files : data.data.videos;
            this.videos.push(...videos);
            this.nextPageToken = data.data.next_page_token;
          } else {
            alert(`Error: ${data.message}`);
          }
        },
        (error) => {
          this.loadingMoreVideos = false;
          console.error('Error:', error);
        }
      );
      this.params.channel_id = null;
      this.params.channel_url = null;
    }
  }

  toggleCardSelection(video: any) {
    if (!video.isSelected) {
      video.isSelected = false;
    }

    video.isSelected = !video.isSelected;
    let video_url = this.type == 'dropbox' ? video.path_display : video.video_url;
    if (video.isSelected) {
      this.selectedVideos.push(video_url);
    } else {
      const index = this.selectedVideos.indexOf(video_url);
      if (index !== -1) {
        this.selectedVideos.splice(index, 1);
      }
    }
  }

  importVideo() {
    if (this.selectedVideos.length > 0) {
      let formData = {};

      if (this.type == 'youtube') {
        formData = {
          video_urls: this.selectedVideos,
          channel_uid: this.activeChannelId,
          tenant_uid: this.tenantId,
          encoding_profile: '4000kbps',
          env: env.qmsEnvServer,
        };
      } else if (this.type == 'vimeo') {
        formData = {
          video_urls: this.selectedVideos,
          channel_uid: this.activeChannelId,
          tenant_uid: this.tenantId,
          encoding_profile: '500kbps',
          env: env.qmsEnvServer,
        };
      } else if (this.type == 'dropbox') {
        formData = {
          file_paths: this.selectedVideos,
          channel_uid: this.activeChannelId,
          tenant_uid: this.tenantId,
          encoding_profile: '500kbps',
          env: env.qmsEnvServer,
          dbx_token: localStorage.getItem('dropbox_token'),
          // 'sl.Bx6BJbXmXAznqdmPqQivO-a4B3QvmabIukWWNB48kcYZimJRqpfhfIKdAfIpw0NhSBrcFLKA3c0OS-PZghhdeg6shvHsxZrxaYpTg8WQXfApEDsWXuojXrvcJWR7IaczHNJELV7eliX9yHg',
        };
      }

      this.importerApiService.importVideos(formData, this.type).subscribe(
        (data: any) => {
          this.notifier.notify('success', data.data);
        },
        (error) => {
          this.notifier.notify('error', error);
        }
      );
    }
  }

  importSpecificVideoFunc() {
    this.specificVideoUrl = this.videoForm.value.videoUrl;
    if (this.specificVideoUrl) {
      let formData = {};

      if (this.type == 'youtube') {
        formData = {
          video_urls: [this.specificVideoUrl],
          channel_uid: this.activeChannelId,
          tenant_uid: this.tenantId,
          encoding_profile: '4000kbps',
          env: env.qmsEnvServer,
        };
      } else if (this.type == 'vimeo') {
        formData = {
          video_urls: [this.specificVideoUrl],
          channel_uid: this.activeChannelId,
          tenant_uid: this.tenantId,
          encoding_profile: '500kbps',
          env: env.qmsEnvServer,
        };
      } else if (this.type == 'dropbox') {
        this.specificVideoUrl = this.transformURL(this.specificVideoUrl);
        if (!this.specificVideoUrl) {
          this.notifier.notify('error', 'Import failed');
          return;
        }
        formData = {
          file_paths: [this.specificVideoUrl],
          channel_uid: this.activeChannelId,
          tenant_uid: this.tenantId,
          encoding_profile: '500kbps',
          env: env.qmsEnvServer,
          dbx_token: localStorage.getItem('dropbox_token'),
          // 'sl.Bx6BJbXmXAznqdmPqQivO-a4B3QvmabIukWWNB48kcYZimJRqpfhfIKdAfIpw0NhSBrcFLKA3c0OS-PZghhdeg6shvHsxZrxaYpTg8WQXfApEDsWXuojXrvcJWR7IaczHNJELV7eliX9yHg',
        };
      }

      this.importerApiService.importVideos(formData, this.type).subscribe(
        (data: any) => {
          this.notifier.notify('success', data.data);
          this.videoForm.reset();
        },
        (error) => {
          this.notifier.notify('error', error);
        }
      );
    }
  }

  handleImportVideos() {
    const dialogData = new ConfirmDialogModel(
      'Note',
      'Kindly note that there may be a delay in imported videos appearing in the video library. We appreciate your patience during this process.',
      'Confirm'
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '360px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        if (this.importVideosFromChannel) {
          this.importVideo();
        } else {
          this.importSpecificVideoFunc();
        }
      }
    });
  }

  backBtn() {
    this.importSpecificVideo = false;
    this.importVideosFromChannel = false;
  }

  isLoadingDisabled() {
    if (this.type == 'youtube' || this.type == 'dropbox') {
      return !this.nextPageToken;
    } else if (this.type == 'vimeo') {
      return this.videos.length >= this.raw_data.total;
    }
  }

  // for dropbox only
  transformURL(inputURL: string): string {
    const urlPattern = /^(http|https):\/\/[^ "]+$/;
    if (!urlPattern.test(inputURL)) {
      return inputURL;
    }
    console.log('og', inputURL);
    // Remove "https://www.dropbox.com/home" from the URL
    let trimmedURL = '';
    if (inputURL.includes('https://www.dropbox.com/home')) {
      trimmedURL = inputURL.replace('https://www.dropbox.com/home', '');
    } else if (inputURL.includes('https://www.dropbox.com/personal')) {
      trimmedURL = inputURL.replace('https://www.dropbox.com/personal', '');
    } else if (inputURL.includes('https://www.dropbox.com/preview')) {
      trimmedURL = inputURL.replace('https://www.dropbox.com/preview', '');
    } else {
      this.notifier.notify('error', 'Invalid url');
      return;
    }

    trimmedURL = trimmedURL.replace(/%20/g, ' ');
    trimmedURL = trimmedURL.replace('?preview=', '/');
    console.log(trimmedURL);
    return `${trimmedURL}`;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  getLogInStatus(): void {
    this.authService.getAccountInfo().subscribe((account: any) => {
      this.importerApiService.fetchAwsCredentials(account.tenant_id).subscribe(
        (response) => {
          if (!response || (typeof response === 'object' && Object.keys(response).length === 0)) {
            this.isLoggedInAws = false;
            console.log('Received an empty response, assuming the tenant has not yet provided AWS Credentials');
          } else {
            this.isLoggedInAws = true;
          }
        },
        (error) => {
          this.notifier.notify('error', 'Credentials Checker API not connected');
        }
      );
    });
  }

  awsS3ImportVideos(): void {
    console.log('Import Video Clicked');
    this.importVideosFromAwsS3 = !this.importVideosFromAwsS3;
    this.isFetchingVideosFromAwsS3 = !this.isFetchingVideosFromAwsS3;
    this.isLoggedInAws = true;
    this.showVideoList = false;

    console.log(this.awsS3Form);
    console.log(this.awsS3Form.value);

    this.importerApiService.submitAwsCredentials(this.awsS3Form.value).subscribe(
      (response) => {
        console.log('success');
        this.isFetchingVideosFromAwsS3 = false;
        this.showVideoList = true;
        this.videos = this.getVideos();
      },
      (error) => {
        //    this.isFetchingVideosFromAwsS3 = false; // delete this
        //    this.showVideoList = true; // delete this
        //    this.videos = this.getVideos(); // delete this
        this.notifier.notify('error', 'Credentials Receiver API not connected');
      }
    );

    //    // Simulate loading time (e.g., fetching videos from API)
    //    setTimeout(() => {
    //      this.isFetchingVideosFromAwsS3 = false;
    //      this.showVideoList = true;
    //      this.videos = this.getVideos(); // Fetch videos here
    //    }, 4000); // Adjust timeout as needed
  }

  getVideos(): any[] {
    this.authService.getAccountInfo().subscribe((account: any) => {
      this.importerApiService.fetchAwsVideos(account.tenant_id).subscribe(
        (response) => {
          return response.videos;
        },
        (error) => {
          this.notifier.notify('error', 'Get Videos from AWS Bucket API not conencted');
        }
      );
    });

    return [];

    //    return [
    //      {
    //        title: 'Many Colors of Jackrabbit Tails.mp4',
    //        description: 'Philosophical review on the biology of Jackrabbits',
    //      },
    //      { title: 'Patikim ng Pinya.mp4', description: 'A fruity surprise' },
    //      { title: 'Stairway to Heaven.mp4', description: 'Students explore an abandoned staircase' },
    //    ];
  }

  toggleAllRows(event: MatCheckboxChange) {
    if (event.checked) {
      this.selection.select(...this.videos);
    } else {
      this.selection.clear();
    }
  }

  isAllSelected() {
    return this.selection.selected.length === this.videos.length;
  }

  handleSortChange(event: Sort) {
    // TODO Handle sort change
  }

  changeCredentialsAws() {
    console.log('Change Cred Clicked');
    this.isLoggedInAws = false;
    this.importVideosFromAwsS3 = false;
  }

  startAwsImport() {
    this.isFetchingVideosFromAwsS3 = true;
    console.log('Start Doing Stuff');
    const selectedItems = JSON.stringify(this.selection.selected);
    console.log('Selected Items: ', JSON.stringify(selectedItems));
    this.importerApiService.submitAwsVideosSelected(selectedItems).subscribe(
      (response) => {
        this.isFetchingVideosFromAwsS3 = false;
        console.log('Done Sending List');
      },
      (error) => {
        this.notifier.notify('error', 'List of Selected videos from bucket Receiver API not connected');
      }
    );

    this.isFetchingVideosFromAwsS3 = false;
  }

  importVideosAws() {
    console.log('add video clicked');
    this.awsS3ImportVideos();
    this.importVideosFromAwsS3 = true;
    this.isLoggedInAws = true;
  }

  handleDeleteVideo(video: Video) {
    const dialogData = new ConfirmDialogModel('Are you sure you want to delete this video?', '');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '470px',
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        const { uuid: videoId } = video;
        console.log(video);
        this.isLoading = true;
        this.videoService.deleteVideoV3(videoId).subscribe(
          () => {
            this.notifier.notify('success', 'Video successfully deleted');
            this.isLoading = false;
            this.params.page = 1;
            this.getVideos();
          },
          (error) => {
            this.notifier.notify('error', error);
            this.isLoading = false;
          }
        );
      }
    });
  }

  setAsAdvertisementVideo(is_advertisement: boolean, videoId: string): void {
    let message = is_advertisement
      ? "Are you sure you want to set this video as advertisement videos?  Ad videos will play as intended, but they won't show up in the channel guide list."
      : 'Are you sure you want to remove the ad designation from the selected video?';
    const dialogData = new ConfirmDialogModel('Confirm', message, 'Confirm');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: is_advertisement ? '350px' : '375px',
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.isLoading = true;
        this.videoService.updateVideoAsAd({ is_advertisement: is_advertisement }, videoId).subscribe(
          (result) => {
            this.notifier.notify('success', 'Video successfully updated');
            this.isLoading = false;
            this.params.page = 1;
            this.getVideos();
          },
          (error) => {
            this.notifier.notify('error', error);
            this.isLoading = false;
          }
        );
      }
    });
  }

  showAdVideo(videoId: string) {
    const dialogRef = this.dialog.open(ShowAdVideoDialogComponent, {
      width: '800px',
      height: '400px',
      data: { videoId: videoId },
      disableClose: false,
      panelClass: 'app-full-bleed-dialog',
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {});
  }

  handleOpenAttachToAVideo(videoId: string) {
    const dialogData = new AssignTrailerClipModel();
    const dialogRef = this.dialog.open(AssignTrailerClipComponent, {
      width: '850px',
      data: { ...dialogData, activeVideoId: videoId, assignType: 'Advertisement' },
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.selectedAdVideo = dialogResult;
        this.videoService.attachAdToVideo({ ad_video_id: this.selectedAdVideo.uuid }, videoId).subscribe(
          (res: any) => {
            this.notifier.notify('success', res.message);
            this.params.page = 1;
            this.getVideos();
          },
          (error) => {
            this.notifier.notify('error', error);
            this.isLoading = false;
          }
        );
      }
    });
  }

  handleDownloadVideo(video: Video) {
    this.videoService.getDownloadLink(video.uuid).subscribe(
      (res) => {
        const { download_link } = res;
        window.location.href = download_link;
      },
      (err) => {
        this.notifier.notify('error', err);
      }
    );
  }

  handleReEncode(video: Video) {
    this.isLoading = true;
    // added video type check
    if (video.video_type === 'MERGED') {
      this.videoService.reMerging(video.uuid).subscribe(
        (res) => {
          this.getVideos();
          this.isLoading = false;
          this.notifier.notify('success', 'Start encoder successfully');
        },
        (err) => {
          this.isLoading = false;
          this.notifier.notify('error', err);
        }
      );
    } else {
      this.videoService.reEncoder(video.uuid, { corrupted: true }).subscribe(
        (res) => {
          this.getVideos();
          this.isLoading = false;
          this.notifier.notify('success', 'Start encoder successfully');
        },
        (err) => {
          this.isLoading = false;
          this.notifier.notify('error', err);
        }
      );
    }
  }

  handleEditThumbnail(video: Video) {
    const dialogData = new EditThumbnailDiaLogModel('video', video, () => this.getVideos());
    this.dialog.open(EditThumbnailDialogComponent, {
      width: '500px',
      data: dialogData,
      disableClose: true,
    });
  }

  handleAddToCategory(video: Video) {
    const dialogData = new ListCategoryDialogModel('video', video.uuid);
    const dialogRef = this.dialog.open(ListCategoryDialogComponent, {
      width: '500px',
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.getVideos();
      }
    });
  }

  handlePreviewVideo(video: Video) {
    const dialogData = new PreviewVideoModel(video);
    const dialogRef = this.dialog.open(PreviewVideoComponent, {
      width: '1000px',
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {});
  }

  handleEditVideo(video: Video) {
    this.router.navigate(['video-library', 'video-settings', video.uuid]);
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  getThumbnail(row: any) {
    if (row.thumbnail) {
      let f_thumbnail = row.thumbnail;
      return f_thumbnail;
    } else {
      return 'assets/images/no-image.png';
    }
  }
}

export class VideoImporterModel {
  constructor(public type: any) {}
}
