<div mat-dialog-content>
  <app-spinner *ngIf="isLoading"></app-spinner>

  <div fxLayout="column" fxLayoutGap="1rem">
    <h2 class="header">Invite guests to your live broadcast</h2>
    <p class="content-item">Share the link below with up to 6 guests.</p>

    <div class="content-item" fxLayout="row" *ngIf="!!emailGuests.length">
      <div [style.margin-right.px]="10">
        <mat-icon>email</mat-icon>
      </div>
      <div class="content-item" fxFlex>
        <mat-chip-list>
          <mat-chip *ngFor="let email of emailGuests" [removable]="true" (removed)="removeEmail(email)">
            {{ email }}
            <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
    <div class="content-item w-100" *ngIf="emailGuests?.length < 6">
      <mat-form-field [style.width]="'80%'">
        <input
          id="name"
          name="name"
          type="text"
          matInput
          placeholder="Enter email address"
          email
          [(ngModel)]="email"
          #name="ngModel"
          (keyup.enter)="email && !name.invalid && emailGuests?.length < 6 && addEmail()"
        />
      </mat-form-field>
      <button
        mat-flat-button
        class="add-button"
        (click)="addEmail()"
        [disabled]="!email || name.invalid || !name || emailGuests?.length >= 6"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</div>
<div *ngIf="!!emailGuests.length" mat-dialog-actions fxLayout="column" fxLayoutGap="16px" style="text-align: center">
  <div fxLayout="row" class="w-100">
    <button fxFlex mat-flat-button (click)="handleInviteGuest()">SEND</button>
  </div>
</div>
