import { Injectable } from '@angular/core';
import { ApiService } from '@core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

declare const FB: any;

@Injectable({
  providedIn: 'root',
})
export class FacebookLiveStreamService {
  private readonly authType = 'rerequest';
  private readonly configId = '827780609119915';
  private readonly scopeList = [
    'publish_video',
    'pages_read_engagement',
    'pages_manage_posts',
    'pages_show_list',
    'pages_read_user_content',
    'pages_manage_engagement',
  ];
  private readonly serverUrl = environment.liveChatServer;

  constructor(private api: ApiService) {}

  // Get access token
  getLongLivedToken(access_token: string): Observable<any> {
    return this.api.post(`${this.serverUrl}/auth/facebook`, { access_token: access_token });
  }

  login(): Observable<any> {
    return new Observable((observer) => {
      FB.login(
        (response: any) => {
          if (response.authResponse) {
            observer.next(response);
          } else {
            observer.error(response);
          }

          observer.complete();
        },
        {
          config_id: this.configId,
          scope: this.scopeList.join(','),
          auth_type: this.authType,
        }
      );
    });
  }

  logout(accessToken: string): Observable<any> {
    const params = {
      access_token: accessToken,
    };

    return new Observable((observer) => {
      FB.logout(params, () => {
        observer.complete();
      });
    });
  }

  // Get user information
  getUser(accessToken: string, userId: string): Observable<any> {
    const params = {
      access_token: accessToken,
    };

    return new Observable((observer) => {
      FB.api(`/${userId}/`, 'GET', params, (response: any) => {
        if (response && !response.error) {
          observer.next(response);
          observer.complete();
        } else {
          observer.error(response);
        }
      });
    });
  }

  // Get pages
  getPages(accessToken: string, userId: string): Observable<any> {
    const params = {
      access_token: accessToken,
    };

    return new Observable((observer) => {
      FB.api(`/${userId}/accounts`, 'GET', params, (response: any) => {
        if (response && !response.error) {
          observer.next(response);
          observer.complete();
        } else {
          observer.error(response);
        }
      });
    });
  }

  // Get groups
  getGroups(accessToken: string, userId: string): Observable<any> {
    const params = {
      access_token: accessToken,
    };

    return new Observable((observer) => {
      FB.api(`/${userId}/groups`, 'GET', params, (response: any) => {
        if (response && !response.error) {
          observer.next(response);
          observer.complete();
        } else {
          observer.error(response);
        }
      });
    });
  }

  // Create live video
  createLiveVideo(accessToken: string, accountId: string): Observable<any> {
    const params = {
      status: 'LIVE_NOW',
      access_token: accessToken,
    };

    return new Observable((observer) => {
      FB.api(`/${accountId}/live_videos`, 'POST', params, (response: any) => {
        if (response && !response.error) {
          observer.next(response);
          observer.complete();
        } else {
          observer.error(response);
        }
      });
    });
  }

  // Get live video information
  getLiveVideo(accessToken: string, liveVideoId: string): Observable<any> {
    const params = {
      fields: 'permalink_url',
      access_token: accessToken,
    };

    return new Observable((observer) => {
      FB.api(`/${liveVideoId}`, params, (response: any) => {
        if (response && !response.error) {
          observer.next(response);
          observer.complete();
        } else {
          observer.error(response);
        }
      });
    });
  }

  // End live video
  endLiveVideo(accessToken: string, liveVideoId: string): Observable<any> {
    const params = {
      end_live_video: true,
      access_token: accessToken,
    };

    return new Observable((observer) => {
      FB.api(`/${liveVideoId}/`, params, 'POST', (response: any) => {
        if (response && !response.error) {
          observer.next(response);
          observer.complete();
        } else {
          observer.error(response);
        }
      });
    });
  }

  // Get comments from live stream
  getComments(accessToken: string, liveVideoId: string): Observable<any> {
    return new Observable((observer) => {
      FB.api(`/${liveVideoId}/comments?access_token=${accessToken}`, (response: any) => {
        if (response && response.data) {
          observer.next(response);
        } else {
          observer.next({ data: [] });
        }

        observer.complete();
      });
    });
  }
}
