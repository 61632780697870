import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BroadcastStudioMainService } from '@app/cms/broadcast-studio/broadcast-studio.service';
import { BroadcastStudioService } from '@app/shared/services';
import { EmailGuestDialogComponent } from '@shared/components/email-guest-dialog/email-guest-dialog.component';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-invite-guest-dialog',
  templateUrl: './invite-guest-dialog.component.html',
  styleUrls: ['./invite-guest-dialog.component.scss'],
})
export class InviteGuestDialogComponent implements OnInit {
  @ViewChild('inputLink') inputLink: ElementRef<HTMLInputElement>;
  emails = new FormArray([]);
  studioId: string;
  emailGuests: string[] = [];
  email: string;
  requireAuthentication: boolean;
  isLoading: boolean;
  meetingUrl: any;
  buttonStatus: boolean = false;
  inviteUrl = '';
  guestUrl = '';

  constructor(
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: InviteGuestDialogModel,
    private broadcastStudioService: BroadcastStudioService,
    private broadcastStudioMainService: BroadcastStudioMainService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<InviteGuestDialogComponent>,
    private dialog: MatDialog
  ) {
    this.studioId = data.studioId;
    this.guestUrl = localStorage.getItem('link');
  }

  copyLink() {
    const element = document.createElement('textarea');
    element.value = this.guestUrl;
    document.body.appendChild(element);
    element.select();

    document.execCommand('copy');
    document.body.removeChild(element);

    this.notifier.notify('success', 'Copied');
  }

  addMoreEmail() {
    this.emails.push(new FormControl('', [Validators.required, Validators.email]));
  }

  handleInviteGuest() {
    const data = {
      emails: this.emailGuests,
      invite_url: 'http://localhost:4201/guest/broadcasts-studio',
      studio_uuid: this.studioId,
    };

    this.broadcastStudioService.doInviteGuest(data).subscribe(console.log);
  }

  removeEmail(email: string) {
    this.emailGuests = this.emailGuests.filter((e) => e !== email);
  }

  addEmail() {
    this.emailGuests.push(this.email);
    this.email = '';
  }

  ngOnInit(): void {
    this.meetingUrl = localStorage.getItem('link');
    this.inviteUrl = `${window.location.origin}/guest/broadcasts-studio/${this.studioId}`;
  }

  emailLink(): void {
    const dialogData = new InviteGuestDialogModel(this.studioId);

    this.dialog.open(EmailGuestDialogComponent, {
      width: '600px',
      data: dialogData,
    });
  }
}

export class InviteGuestDialogModel {
  constructor(public studioId: string) {}
}
