import { Injectable, OnInit } from '@angular/core';
import { StudioStorageService } from '@app/cms/broadcast-studio/services/studio-storage.service';
import { environment } from '@env/environment.staging';
import { BehaviorSubject } from 'rxjs';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { FacebookLiveStreamService } from '../../services/facebook-live.service';

let signinWindow: any;
let accessToken: any;

@Injectable({
  providedIn: 'root',
})
export class AddOutputDialogService implements OnInit {
  accessTokenStatus = new BehaviorSubject(false);
  accessTokenStatus1 = new BehaviorSubject(false);
  private webSocketSubject: WebSocketSubject<any>;

  accessTokenStatus2 = new BehaviorSubject(false);

  youtubeAccessTokenStatus = new BehaviorSubject(false);
  youtubeAccessTokenStatus1 = new BehaviorSubject(false);
  youtubeAccessTokenStatus2 = new BehaviorSubject(false);

  facebookTokenStatus = new BehaviorSubject(false);
  facebookTokenStatus1 = new BehaviorSubject(false);

  facebookTokenStatus2 = new BehaviorSubject(false);

  tvstartupChannelStatus = new BehaviorSubject(false);

  instagramToggleStatus = new BehaviorSubject(false);
  instagramToggleStatus1 = new BehaviorSubject(false);
  instagramToggleStatus2 = new BehaviorSubject(false);

  rtmpToggleStatus = new BehaviorSubject(false);
  rtmpToggleStatus1 = new BehaviorSubject(false);
  rtmpToggleStatus2 = new BehaviorSubject(false);

  tiktokToggleStatus = new BehaviorSubject(false);
  tiktokToggleStatus1 = new BehaviorSubject(false);
  tiktokToggleStatus2 = new BehaviorSubject(false);

  twitterToggleStatus = new BehaviorSubject(false);
  twitterToggleStatus1 = new BehaviorSubject(false);
  twitterToggleStatus2 = new BehaviorSubject(false);

  tvStartupVisibilityStatus = new BehaviorSubject(false);
  facebookVisibilityStatus1 = new BehaviorSubject(false);
  facebookVisibilityStatus2 = new BehaviorSubject(false);
  facebookVisibilityStatus = new BehaviorSubject(false);

  youtubeVisibilityStatus = new BehaviorSubject(false);
  youtubeVisibilityStatus1 = new BehaviorSubject(false);
  youtubeVisibilityStatus2 = new BehaviorSubject(false);

  twitchVisibilityStatus = new BehaviorSubject(false);
  twitchVisibilityStatus1 = new BehaviorSubject(false);
  twitchVisibilityStatus2 = new BehaviorSubject(false);

  instagramVisibilityStatus = new BehaviorSubject(false);
  instagramVisibilityStatus1 = new BehaviorSubject(false);
  instagramVisibilityStatus2 = new BehaviorSubject(false);

  rtmpVisibilityStatus = new BehaviorSubject(false);
  rtmpVisibilityStatus1 = new BehaviorSubject(false);

  rtmpVisibilityStatus2 = new BehaviorSubject(false);

  tiktokVisibilityStatus = new BehaviorSubject(false);
  tiktokVisibilityStatus1 = new BehaviorSubject(false);

  tiktokVisibilityStatus2 = new BehaviorSubject(false);

  twitterVisibilityStatus = new BehaviorSubject(false);
  twitterVisibilityStatus1 = new BehaviorSubject(false);

  twitterVisibilityStatus2 = new BehaviorSubject(false);

  randomKey: any;

  constructor(private storage: StudioStorageService, private facebookService: FacebookLiveStreamService) {
    this.webSocketSubject = webSocket('wss://pubsub-edge.twitch.tv');
  }

  ngOnInit(): void {
    // this.randomKey = this.storage.get('randomKey');
  }
  getMessageStream() {
    return this.webSocketSubject.asObservable();
  }

  subscribeToTopic(topic: string, oauthToken: string) {
    setInterval(() => {
      const data = {
        type: 'LISTEN',
        data: {
          topics: [topic],
          auth_token: oauthToken,
        },
      };

      this.webSocketSubject.next(data);
    }, 2000);
  }

  loginTwitch() {
    const twitchUrl = window.location.origin;

    var signinUrl = `https://id.twitch.tv/oauth2/authorize?response_type=token&client_id=${environment.twitchClientId}&redirect_uri=${twitchUrl}/broadcast-studio&scope=channel%3Amanage%3Apolls+channel%3Aread%3Apolls+channel%3Aread%3Astream_key+user%3Abot+user%3Awrite%3Achat+channel%3Aread%3Aredemptions+bits%3Aread`;

    signinWindow = window.open(signinUrl, '_blank', 'height=700,width=500, top=500,left=500');

    let childUrl;

    let myInterval = setInterval(() => {
      childUrl = signinWindow.location.href;

      if (childUrl.includes('access_token=')) {
        const url = new URL(childUrl);
        const params = new URLSearchParams(url.hash.slice(1));
        accessToken = params.get('access_token');

        if (accessToken) {
          clearInterval(myInterval);

          signinWindow.close();

          this.clickTwitch(accessToken, myInterval, signinWindow);
        }
      }
    }, 1000);
  }

  async clickTwitch(token: any, myInterval: any, popupWindow: any) {
    const response = await fetch('https://id.twitch.tv/oauth2/validate', {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    });

    response.json().then((res) => {
      this.storage.set('twitchName', res.login);
      this.getStreamKey(res, token, myInterval, popupWindow);
    });
  }

  async getStreamKey(res: any, token: any, myInterval: any, popupWindow: any) {
    this.storage.set('twitchClientId', res.client_id);
    this.storage.set('twitchUserId', res.user_id);

    const response = await fetch(`https://api.twitch.tv/helix/streams/key?broadcaster_id=${res.user_id}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Client-Id': `${res.client_id}`,
      }),
    });

    response.json().then((res) => {
      const streamKey = res.data[0].stream_key;

      streamKey
        ? this.storage.set('twitchStreamKeyValue', streamKey)
        : this.storage.set('twitchStreamKeyValue', 'false');

      this.accessTokenStatus.next(true);
      this.storage.set('twitchToken', token);

      this.storage.set('twitchVisible', 'true');

      this.twitchVisibilityStatus.next(true);
    });
  }

  // Login to Facebook
  async loginFacebook() {
    try {
      // Login
      const loginResponse = await this.facebookService.login().toPromise();
      const userId = loginResponse.authResponse.userID;

      var expiryDate = Date.now() + loginResponse.authResponse.expiresIn * 1000;

      // Get long lived token
      const accessToken = loginResponse.authResponse.accessToken;
      const tokenResponse = await this.facebookService.getLongLivedToken(accessToken).toPromise();

      // Calculate expiry date
      const longLivedToken = tokenResponse.access_token;

      if (tokenResponse.expiry_date) {
        expiryDate = tokenResponse.expiry_date;
      }

      // Store account information
      this.storage.setFacebookAccessToken(longLivedToken);
      this.storage.setFacebookUserId(userId);
      this.storage.setFacebookExpiryDate(expiryDate);

      this.storage.set('facebookVisible', 'true');
      this.storage.set('isFacebookEnabled', 'true');

      // Get user information
      this.getFacebookUser(accessToken, userId);

      this.facebookVisibilityStatus.next(true);
      this.facebookTokenStatus.next(true);
    } catch (error) {
      console.error(error);
    }
  }

  // Get user information
  async getFacebookUser(accessToken: string, userId: string) {
    try {
      // Get user
      const response = await this.facebookService.getUser(accessToken, userId).toPromise();

      // Store account name
      this.storage.setFacebookAccountName(response.name);
    } catch (error) {
      console.error(error);
    }
  }
}
