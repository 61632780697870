import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InviteGuestDialogModel } from '@shared/components';
import { NotifierService } from 'angular-notifier';
import emailjs from 'emailjs-com';

@Component({
  selector: 'app-email-guest-dialog',
  templateUrl: './email-guest-dialog.component.html',
  styleUrls: ['./email-guest-dialog.component.scss'],
})
export class EmailGuestDialogComponent implements OnInit {
  studioId: string;
  emailGuests: string[] = [];
  email: string;
  isLoading: boolean;
  guestUrl: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InviteGuestDialogModel,
    private notify: NotifierService,
    private dialogRef: MatDialogRef<EmailGuestDialogComponent>
  ) {
    this.studioId = data.studioId;
    this.guestUrl = localStorage.getItem('link');
  }

  ngOnInit(): void {}

  handleInviteGuest() {
    this.isLoading = true;

    emailjs
      .send(
        'service_flfo4b3',
        'template_dto51o8',
        {
          email_to: this.emailGuests,
          link: this.guestUrl,
        },
        'b-zCEAsaEX8hpwqYI'
      )
      .then((response: any) => {
        this.notify.notify('success', 'Email sent');

        this.dialogRef.close();
      })
      .catch((error: any) => {
        console.error(error);

        this.notify.notify('error', 'Failed to send email');
        this.isLoading = false;

        this.dialogRef.close();
      });
  }

  removeEmail(email: string) {
    this.emailGuests = this.emailGuests.filter((e) => e !== email);
  }

  addEmail() {
    this.emailGuests.push(this.email);
    this.email = '';
  }
}
