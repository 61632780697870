import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StudioStorageService {
  // YouTube
  private readonly YT_ACCOUNT_NAME = 'studioYtAccountName';
  private readonly YT_ACCESS_TOKEN = 'studioYtAccessToken';
  private readonly YT_REFRESH_TOKEN = 'studioYtRefreshToken';
  private readonly YT_EXPIRY_DATE = 'studioYtExpiryDate';
  private readonly YT_BROADCAST_ID = 'studioYtBroadcastId';
  private readonly YT_LIVECHAT_ID = 'studioYtLiveChatId';

  // Facebook
  private readonly FB_ACCOUNT_NAME = 'studioFbAccountName';
  private readonly FB_ACCOUNT_TYPE = 'studioFbAccountType';
  private readonly FB_ACCESS_TOKEN = 'studioFbAccessToken';
  private readonly FB_EXPIRY_DATE = 'studioFbExpiryDate';
  private readonly FB_USER_ID = 'studioFbUserId';
  private readonly FB_PAGE_ID = 'studioFbPageId';
  private readonly FB_LIVE_VIDEO_ID = 'studioFbLiveVideoId';

  // Twitch
  private readonly TWITCH_ACCOUNT_NAME = 'studioTwitchAccountName';
  private readonly TWITCH_ACCESS_TOKEN = 'studioTwitchAccessToken';
  private readonly TWITCH_EXPIRY_DATE = 'studioTwitchExpiryDate';
  private readonly TWITCH_USER_ID = 'studioTwitchUserId';
  private readonly TWITCH_STREAM_KEY = 'studioTwitchStreamKey';

  constructor() {}

  public set(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  public get(key: string): string | null {
    return localStorage.getItem(key);
  }

  public remove(key: string): void {
    localStorage.removeItem(key);
  }

  public setYouTubeAccountName(name: string): void {
    this.set(this.YT_ACCOUNT_NAME, name);
  }

  public getYouTubeAccountName(): string | null {
    return this.get(this.YT_ACCOUNT_NAME);
  }

  public setYouTubeAccessToken(token: string): void {
    this.set(this.YT_ACCESS_TOKEN, token);
  }

  public getYouTubeAccessToken(): string | null {
    return this.get(this.YT_ACCESS_TOKEN);
  }

  public setYouTubeRefreshToken(token: string): void {
    this.set(this.YT_REFRESH_TOKEN, token);
  }

  public getYouTubeRefreshToken(): string | null {
    return this.get(this.YT_REFRESH_TOKEN);
  }

  public setYouTubeExpiryDate(timestamp: number): void {
    this.set(this.YT_EXPIRY_DATE, timestamp.toString());
  }

  public getYouTubeExpiryDate(): Date | null {
    const timestamp = this.get(this.YT_EXPIRY_DATE);

    return timestamp ? new Date(parseInt(timestamp, 10)) : null;
  }

  public setYouTubeBroadcastId(id: string): void {
    this.set(this.YT_BROADCAST_ID, id);
  }

  public getYouTubeBroadcastId(): string | null {
    return this.get(this.YT_BROADCAST_ID);
  }

  public setYouTubeLiveChatId(id: string): void {
    this.set(this.YT_LIVECHAT_ID, id);
  }

  public getYouTubeLiveChatId(): string | null {
    return this.get(this.YT_LIVECHAT_ID);
  }

  // Remove YouTube account
  public removeYouTube(): void {
    this.remove(this.YT_ACCOUNT_NAME);
    this.remove(this.YT_ACCESS_TOKEN);
    this.remove(this.YT_REFRESH_TOKEN);
    this.remove(this.YT_EXPIRY_DATE);
    this.remove(this.YT_BROADCAST_ID);
    this.remove(this.YT_LIVECHAT_ID);
  }

  public setFacebookAccountName(name: string): void {
    this.set(this.FB_ACCOUNT_NAME, name);
  }

  public getFacebookAccountName(): string | null {
    return this.get(this.FB_ACCOUNT_NAME);
  }

  public setFacebookAccountType(type: string): void {
    this.set(this.FB_ACCOUNT_TYPE, type);
  }

  public getFacebookAccountType(): string | null {
    return this.get(this.FB_ACCOUNT_TYPE);
  }

  public setFacebookAccessToken(token: string): void {
    this.set(this.FB_ACCESS_TOKEN, token);
  }

  public getFacebookAccessToken(): string | null {
    return this.get(this.FB_ACCESS_TOKEN);
  }

  public setFacebookExpiryDate(timestamp: number): void {
    this.set(this.FB_EXPIRY_DATE, timestamp.toString());
  }

  public getFacebookExpiryDate(): Date | null {
    const timestamp = this.get(this.FB_EXPIRY_DATE);

    return timestamp ? new Date(parseInt(timestamp, 10)) : null;
  }

  public setFacebookUserId(id: string): void {
    this.set(this.FB_USER_ID, id);
  }

  public getFacebookUserId(): string | null {
    return this.get(this.FB_USER_ID);
  }

  public setFacebookPageId(id: string): void {
    this.set(this.FB_PAGE_ID, id);
  }

  public getFacebookPageId(): string | null {
    return this.get(this.FB_PAGE_ID);
  }

  public setFacebookLiveVideoId(id: string): void {
    this.set(this.FB_LIVE_VIDEO_ID, id);
  }

  public getFacebookLiveVideoId(): string | null {
    return this.get(this.FB_LIVE_VIDEO_ID);
  }

  public removeFacebook(): void {
    this.remove(this.FB_ACCOUNT_NAME);
    this.remove(this.FB_ACCESS_TOKEN);
    this.remove(this.FB_EXPIRY_DATE);
    this.remove(this.FB_ACCOUNT_TYPE);
    this.remove(this.FB_LIVE_VIDEO_ID);
    this.remove(this.FB_USER_ID);
    this.remove(this.FB_PAGE_ID);

    // TODO Improve
    this.remove('facebookInfo');
  }

  public setTwitchAccountName(name: string): void {
    this.set(this.TWITCH_ACCOUNT_NAME, name);
  }

  public getTwitchAccountName(): string | null {
    return this.get(this.TWITCH_ACCOUNT_NAME);
  }

  public setTwitchAccessToken(token: string): void {
    this.set(this.TWITCH_ACCESS_TOKEN, token);
  }

  public getTwitchAccessToken(): string | null {
    return this.get(this.TWITCH_ACCESS_TOKEN);
  }

  public setTwitchExpiryDate(timestamp: number): void {
    this.set(this.TWITCH_EXPIRY_DATE, timestamp.toString());
  }

  public getTwitchExpiryDate(): Date | null {
    const timestamp = this.get(this.TWITCH_EXPIRY_DATE);

    return timestamp ? new Date(parseInt(timestamp, 10)) : null;
  }

  public setTwitchUserId(id: string): void {
    this.set(this.TWITCH_USER_ID, id);
  }

  public getTwitchUserId(): string | null {
    return this.get(this.TWITCH_USER_ID);
  }

  public setTwitchStreamKey(key: string): void {
    this.set(this.TWITCH_STREAM_KEY, key);
  }

  public getTwitchStreamKey(): string | null {
    return this.get(this.TWITCH_STREAM_KEY);
  }

  public removeTwitch(): void {
    this.remove(this.TWITCH_ACCOUNT_NAME);
    this.remove(this.TWITCH_ACCESS_TOKEN);
    this.remove(this.TWITCH_EXPIRY_DATE);
    this.remove(this.TWITCH_STREAM_KEY);
    this.remove(this.TWITCH_USER_ID);
  }
}
