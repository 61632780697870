<div mat-dialog-content>
  <div fxLayout="column" fxLayoutGap="1rem">
    <h2 class="header">Invite guests to your live broadcast</h2>

    <div class="content">
      <p class="content-item">Share the link below with up to 6 guests.</p>

      <div class="content-item w-100">
        <input class="link" fxFlex type="text" [value]="meetingUrl" disabled />
      </div>

      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1rem" class="w-100">
        <button mat-flat-button fxFlex (click)="copyLink()">
          COPY LINK
          <mat-icon class>content_copy</mat-icon>
        </button>

        <button mat-flat-button fxFlex (click)="emailLink()">
          EMAIL LINK
          <mat-icon>email</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
